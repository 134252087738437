import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogPropTwo from './itemProp/BlogPropTwo';

const BlogListView = () => {
    return (
        <>
            <SEO title="Full-spectrum digital solutions, future-ready partnership" />
            <Layout>
                <BreadcrumbOne
                    title="Full-spectrum digital solutions, future-ready partnership"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog List View"
                />

                <div className="main-content">
                    {/* Start Blog Area */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <h2 className="subtitle" style={{ textAlign: 'center' }}>
                                <span className="theme-gradient">See the latest news & blog</span>
                            </h2>
                            <div className="row mt_dec--30">
                                {/* Pass a custom class to handle mobile-specific styles */}
                                <BlogPropTwo
                                    column="col-lg-6 mt--30"
                                    StyleVarProp="box-card-style-default card-list-view"
                                />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area */}
                </div>
            </Layout>
        </>
    );
}

export default BlogListView;
